/* eslint-disable no-restricted-properties */
import React from 'react';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import set from 'lodash/set';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import { SubmissionError } from 'redux-form/immutable';
import Linkify from 'linkifyjs/react';
import moment from 'moment';

import PopperPopover from '@eva/emf/app/shared/ui/Popper/PopperPopover';
import { requestBackend } from '@eva/emf/app/utils/request';
import { appTypes, baseEndpoint, enWeekdays, safeLocalStorage } from '@eva/emf/app/shared/constants';
import {
  redirectTo,
  redirectToExternalUrl,
  isPathStartWith,
  isPathEqual,
  setTitle,
  getParsedUserByAppType,
  windowLocationReload,
  replaceUrl,
  logDebug,
  logError,
  whiteListObject,
  whitelistName,
  whitelistLocation,
  whitelistPayment,
  whitelistSalary,
  validateFile,
  onDropFile,
} from '@eva/emf/app/shared/functions';
import {
  agentContactAccountTypes,
  agentPrefix,
  candidateRegistrationWorkflowCodes,
  chromeExtensionIds,
  cvExtensions,
  defaultBulkSelection,
  employmentTypes,
  endTime,
  entitiesTypes,
  fileExtensions,
  greyColor,
  iframeMode,
  invertedUserTypes,
  jobsStatuses,
  imagesExtensions,
  localHostnames,
  locationKeys,
  maxFileSize,
  minPasswordLength,
  userAccountTypes,
  zeroTime,
  grayColor,
  clearableMessengerQueryParams,
} from 'shared/constants';
import { getTokenPayload, getNextPortalPageUrl } from '@eva/emf/app/shared/functions-ts';

import noImage from 'assets/images/no-user-image.gif';
import missingField from 'assets/images/missing_fields.png';
import errorField from 'assets/images/error.png';

export {
  setTitle,
  redirectTo,
  replaceUrl,
  redirectToExternalUrl,
  isPathStartWith,
  isPathEqual,
  windowLocationReload,
  getNextPortalPageUrl,
  logDebug,
  logError,
  whiteListObject,
  whitelistName,
  whitelistLocation,
  whitelistPayment,
  whitelistSalary,
  validateFile,
  onDropFile,
};

export const noop = () => {};

export const copy = (value) => JSON.parse(JSON.stringify(value));

// Used in mapStateToProps
export const prepareSelector = (reducer: any, path: any) =>
  createSelector(
    (state: any) => state && state.get(reducer),
    (state) => state && state.get(path),
  );

const errorPopupMessage = (
  <div
    className="small margin-top"
    style={{
      lineHeight: '1.5em',
    }}
  >
    <div>If you need more information,</div>
    please contact us.
  </div>
);

// Generate single string from user params
export const userName = (user) => get(user, 'name.displayName' || '');

export const localDate = (timestamp) => {
  if (timestamp) {
    return moment(`${timestamp}+00:00`);
  }
  return {
    format: () => '',
  };
};

export const momentify = (timestamp) => {
  if (!timestamp) {
    return moment();
  } else if (typeof timestamp === 'string') {
    return localDate(timestamp);
  } else if (typeof timestamp === 'object' && timestamp._d) {
    return timestamp;
  }
  throw new Error(`${timestamp} causes momentify error!`);
};

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const getMissingStages = () => ({
  unknownState: {
    name: 'Unknown state',
    color: grayColor,
  },
  noStage: {
    name: 'Stage not found',
    color: grayColor,
  },
  unidentifiedStage: {
    name: 'Unidentified stage',
    color: grayColor,
  },
  rejectedStage: {
    name: 'Rejected',
    color: '#F79BA3',
  },
});

export const guid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

export const syntaxHighlight = (preJson) => {
  const __html = JSON.stringify(preJson, null, 2)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match) => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return `<span class="${cls}">${match}</span>`;
      },
    );
  return { __html };
};

export const updateFirstName = ({ firstName }) => (firstName ? (localStorage.firstName = firstName) : '');

export const wipeStorage = () => {
  try {
    updateFirstName(JSON.parse(localStorage.user));
  } catch (err) {
    // do nothing;
  }
  // @ts-expect-error
  delete safeLocalStorage.token;
  // @ts-expect-error
  delete safeLocalStorage.user;
};

export const temporaryUser = (userDetails) => {
  if (!userDetails.rw) {
    return true;
  }

  return userDetails.rw.workflow_state.code !== candidateRegistrationWorkflowCodes.registered;
};

export const getParsedUser = () => getParsedUserByAppType(appTypes.candidate);

export function addXhrListeners() {
  if (this.xhrProgress && this.xhrLoad) {
    this.xhr.upload.removeEventListener('progress', this.xhrProgress);
    this.xhr.upload.removeEventListener('load', this.xhrLoad);
  }
  this.xhr = new XMLHttpRequest();
  this.xhrProgress = this.xhr.upload.addEventListener(
    'progress',
    (evt) =>
      this.setState({
        progress: evt.lengthComputable ? (evt.loaded / evt.total) * 0.99 : 0.99,
      }),
    false,
  );
  this.xhrLoad = this.xhr.upload.addEventListener(
    'load',
    () => setTimeout(() => this.setState({ progress: 1 }), 100),
    false,
  );
}

export const validatePassword = (password) => {
  if ((password || '').length < minPasswordLength) {
    return translate('Passwords must be at least {{minPasswordLength}} characters in length', {
      minPasswordLength,
    });
  }
};

export const openTags = (messageContent) => {
  let strippedContent = messageContent;
  while (strippedContent.includes('< ')) {
    strippedContent = strippedContent.replace('< ', '');
  }
  const splittedContent = strippedContent.split('/>');
  return splittedContent.reduce((prev, cur, index) => {
    if (index === splittedContent.length - 1) {
      return `${prev}${cur}`;
    }
    const startIndex = cur.lastIndexOf('<');
    const cuttedTag = cur.substr(startIndex + 1);
    const endIndex = cuttedTag.indexOf(' ');
    const tag = cur.substr(startIndex + 1, endIndex).trim();
    return `${prev}${cur.substr(0, startIndex)}${cur.substr(startIndex).trim()}></${tag}>`;
  }, '');
};

export const onlineTooltip = (chat, timeFormat = 'DD/MM/YY hh:mm') => {
  const momentifiedSeenOnline = chat.lastSeenOnline && momentify(chat.lastSeenOnline).format(timeFormat);
  if (chat.isOnline) {
    return `Online from ${momentifiedSeenOnline}`;
  } else if (chat.lastSeenOnline) {
    return `Offline from ${momentifiedSeenOnline}`;
  }
  return 'Never been online';
};

export const rateClass = (rate) => {
  if (rate < 1) {
    return 'one';
  } else if (rate < 2) {
    return 'two';
  } else if (rate < 3) {
    return 'three';
  } else if (rate < 4) {
    return 'four';
  }
  return 'five';
};

export const collectSelections = (json, id) => {
  try {
    const selections = json._embedded.custom_fields.find((item) => item.id === id);
    if (selections) {
      const options = selections._embedded.definition.field.selections;
      if (Array.isArray(selections.value)) {
        return selections.value.map((valueId) => options.find((option) => option.id === valueId));
      }
      return options.find((option) => option.id === selections.value);
    }
    return '?';
  } catch (err) {
    logError(
      new Error('Collect selection id parse error for json.id', {
        cause: err,
      }),
    );
    return [];
  }
};

export const collectLocation = (address) =>
  ['city', 'state', 'postal_code']
    .reduce((prev, cur) => (address[cur] ? [...prev, address[cur]] : prev), [])
    .join(', ');

export const profileImage = (profile) => {
  if (!(profile.photos || []).length) {
    return noImage;
  }
  const profilePhoto = profile.photos.find((item) => item.isAvatar);
  if (!profilePhoto) {
    return noImage;
  }
  return profilePhoto.url;
};

export const getCvUrl = (candidate) => candidate.cvs[0].url;

export const stringsToObjects = (array) => array.map((item) => ({ id: item, label: item }));

export const stringifyActionError = (action) => {
  const { payload = {} } = action.error;
  if (payload.error) {
    return payload.error;
  } else if (payload.errors) {
    return payload.errors
      .map((error) => {
        const joinedPath = Array.isArray(error.path) ? error.path.join('.') : '';
        return `${joinedPath ? `${joinedPath}: ` : ''} ${error.message}`;
      })
      .join(', ');
  } else if (action.error) {
    if (typeof action.error === 'string') {
      return action.error;
    } else if (typeof action.error === 'object') {
      const { url, message } = action.error;
      return `${message} ${url}`;
    }
  }
  return 'Unknown server error';
};

export const kilofy = (value) => (value || '').toString().replace(/000(?=\D|$)/g, 'k');

export const secondsToHms = (counter, forceHours) => {
  const preciseDays = counter % 86400;
  const days = Math.floor(counter / 86400).toString();
  const preciseHours = preciseDays % 3600;
  const hours = Math.floor(preciseDays / 3600).toString();
  const minutes = Math.floor(preciseHours / 60).toString();
  const seconds = Math.floor(preciseHours % 60).toString();
  return `${days !== '0' ? `${days}d ` : ''}${hours === '0' && !forceHours ? '' : `${padTwo(hours)}:`}${padTwo(
    minutes,
  )}:${padTwo(seconds)}`;
};

export const durationToDhm = (duration) => {
  const counter = parseInt(duration, 0);
  const preciseDays = counter % 86400;
  const days = Math.floor(counter / 86400).toString();
  const preciseHours = preciseDays % 3600;
  const hours = Math.floor(preciseDays / 3600).toString();
  const minutes = Math.floor(preciseHours / 60).toString();
  return days === '0' ? `${hours === '0' ? '' : `${hours} h `}${minutes} min` : '>24 h';
};

export function defineJobsFilters() {
  this.filters = [
    {
      label: translate('ALL'),
      statuses: { ids: [] },
    },
    {
      label: translate('ACTIVE'),
      statuses: jobsStatuses.active,
    },
    {
      label: translate('ON HOLD'),
      statuses: jobsStatuses.onHold,
    },
    {
      label: translate('CLOSED'),
      statuses: jobsStatuses.closed,
    },
  ];
}

export function updateBulkSelection() {
  const { bulkSelected } = this.state;
  const candidates = this.state.candidates || this.props.candidates;
  Object.keys(bulkSelected).forEach((compositeId) => {
    if (!candidates.find((candidate) => candidate.compositeId === compositeId)) {
      delete bulkSelected[compositeId];
    }
  });
  const bulkPaneCandidates = candidates.filter((item) => bulkSelected[item.compositeId]);
  this.setState({
    bulkSelected,
    bulkPaneCandidates,
    showBulkPane: Object.values(bulkSelected).filter((item) => item).length,
    ...(bulkPaneCandidates.length ? {} : defaultBulkSelection),
  });
}

export function processBulkSelection(selectedCandidate, evt) {
  if (typeof evt !== 'object') {
    return true;
  }

  const candidates = this.props.candidates || this.state.candidates;
  const { bulkSelected } = this.state;
  const isCheckedEvent = typeof evt.checked === 'boolean';

  if (isCheckedEvent) {
    // let selectCandidate = false;
    // 1. Если кликнули на чекбоксе:
    // 1.1. Если кандидат расчекбокснут - убираем его чексбокность и возвращаем false
    let updatedBulkSelected = copy(bulkSelected);
    // 1.2. Если кандидат чекбокснут - то:
    // 1.2.1. Если shift - делаем выделение от выбранного до текущего
    // Ctrl теперь ни на что не влияет - просто добавляем текущего в выделение
    // 1.2.2. Если балк пустой - меняем selectedCandidate на текущего
    if (!evt.shiftKey) {
      this.setState({
        lastCheckedCandidate: selectedCandidate,
        lastCandidateWasChecked: evt.checked,
      });
      if (evt.checked) {
        updatedBulkSelected = {
          ...bulkSelected,
          [selectedCandidate.compositeId]: true,
        };
      } else {
        delete updatedBulkSelected[selectedCandidate.compositeId];
      }
    } else if (this.state.lastCheckedCandidate) {
      const indexOfSelected = candidates.indexOf(this.state.lastCheckedCandidate);
      const indexOfCurrent = candidates.indexOf(selectedCandidate);
      updatedBulkSelected = candidates
        .slice(Math.min(indexOfSelected, indexOfCurrent), Math.max(indexOfSelected, indexOfCurrent) + 1)
        .reduce(
          (prev, cur) => ({
            ...prev,
            [cur.compositeId]: this.state.lastCandidateWasChecked,
          }),
          bulkSelected,
        );
      document.getSelection().removeAllRanges();
    }

    this.setState(
      {
        bulkSelected: updatedBulkSelected,
      },
      updateBulkSelection.bind(this),
    );

    return false; // select candidate or not
  }

  // Если кликнули не на чекбоксе:
  // Если кандидат чексбокснутый - просто возвращаем true и не меняем балк
  // Если не чексбокнутый - сбрасываем балк, возвращаем true - ОТМЕНЕНО!
  // if (!bulkSelected[selectedCandidate.compositeId]) {
  //   this.setState(defaultBulkSelection);
  // }
  return true;
}

export const updateCandidate = (candidates, compositeId, assignment, reportError = true) => {
  const indexes = [];
  candidates.forEach((candidate, index) => {
    if (candidate.compositeId === compositeId) {
      indexes.push(index);
    }
  });
  if (!indexes.length) {
    if (reportError) {
      logDebug(`Failed to find candidate ${compositeId}`);
    }
    return candidates;
  }
  return candidates.map((candidate, index) => ({
    ...candidate,
    ...(indexes.includes(index)
      ? {
          ...assignment,
          updated: true,
        }
      : {}),
  }));
};

export const overnightAvailability = (originalDays) => {
  const days = copy(originalDays);
  days.forEach((day, index) => {
    const daySlots = day.slots;
    const nextDaySlots = days[index === 6 ? 0 : index + 1].slots;
    const nextStartsOvernightIndex = nextDaySlots.findIndex((item) => item.startTime === zeroTime);
    const currentEndsOvernightIndex = daySlots.findIndex((item) => item.endTime === zeroTime);
    if (currentEndsOvernightIndex > -1) {
      if (nextStartsOvernightIndex > -1) {
        daySlots[currentEndsOvernightIndex].endTime = nextDaySlots[nextStartsOvernightIndex].endTime;
        nextDaySlots.splice(nextStartsOvernightIndex, 1);
      }
    }
  });
  return days;
};

const twilioExtensionIds = () => [
  ...chromeExtensionIds,
  ...(localStorage.extensionId ? [localStorage.extensionId] : []),
];

export const twilioExtensionOnline = () =>
  Promise.all(
    twilioExtensionIds().map(
      (extensionId) =>
        window.chrome.runtime &&
        new Promise((resolve) =>
          window.chrome.runtime.sendMessage(extensionId, { command: 'version' }, (reply) =>
            resolve(reply && reply.version),
          ),
        ),
    ),
  ).then((responses) => responses.some((response) => response));

export const makeTwilioCall = async (modalAlert, phoneNumber, name, avatar, chatUrl, candidateId) =>
  (await twilioExtensionOnline())
    ? twilioExtensionIds().forEach((chromeExtensionId) =>
        window.chrome.runtime.sendMessage(chromeExtensionId, {
          command: 'call',
          phoneNumber,
          name,
          avatar,
          chatUrl,
          candidateId,
        }),
      )
    : modalAlert.open(
        <div>
          {translate('Twilio Chrome extension required to make a phone calls. Please install or enable it.')}
          <a
            style={{ display: 'block' }}
            href={`https://chrome.google.com/webstore/detail/luckylink-twilio-chrome-e/${chromeExtensionIds[0]}`}
            target="_blank"
            rel="noreferrer"
          >
            {translate('Chrome Web Store (download)')}
          </a>
        </div>,
        translate('Twilio extension not installed!'),
      );

export const preparePermanentAvailability = (prePermanentAvailability) => {
  const permanentAvailability = prePermanentAvailability || {
    description: '',
    holidayAllowance: false,
    days: [],
  };
  const weekdaysCodes = moment.weekdays();
  weekdaysCodes.push(weekdaysCodes.shift());
  return {
    ...permanentAvailability,
    description: permanentAvailability.description || '',
    holidayAllowance: permanentAvailability.holidayAllowance || false,
    days: enWeekdays.reduce(
      (prev, cur) => [
        ...prev,
        permanentAvailability.days.find((item) => item.weekDay === cur) || {
          weekDay: cur,
          slots: [],
          flexibleHours: false,
        },
      ],
      [],
    ),
  };
};

export const prepareInterimAvailability = (preInterimAvailability) =>
  preInterimAvailability || {
    description: '',
    slots: [],
  };

export const loadJobPermanentAvailability = (jobId) =>
  requestBackend(`/public-jobs/my/${jobId}`).then(
    (jobProfile: any) => preparePermanentAvailability(jobProfile.permanentAvailability),
    (err) => {
      throw new Error(err);
    },
  );

export const loadJobInterimAvailability = (jobId) =>
  requestBackend(`/public-jobs/my/${jobId}`).then(
    (jobProfile: any) => jobProfile.interimAvailability,
    (err) => {
      throw new Error(err);
    },
  );

export const endifyTime = (time) => (time === zeroTime ? endTime : time);

export const padTwo = (number) => number.toString().padStart(2, 0);

export const fileTooLarge = (modalAlert, file) => {
  if (file.size > maxFileSize) {
    modalAlert.open(
      `${file.name} - ${translate('File size is too large')} (10 Mb max)`,
      'The following files can not be uploaded:',
    );
    return true;
  }
};

export const typeaheadResultsPlaceholder = (isLoading, isEmpty) => {
  if (isLoading) {
    return `${translate('Loading')}...`;
  } else if (isEmpty) {
    return translate('Start typing...');
  }
  return translate('Not found');
};

export const openCandidateChat = (candidateId) =>
  window.open(`${window.location.origin}${agentPrefix}/chat?name=${candidateId}`, window.hrefTarget);

export const popoverEventCanceler = (evt) => {
  evt.stopPropagation();
  return false;
};

export function updateQuery(assignment, reset) {
  const propsOrContext = this.props.router ? 'props' : 'context';
  const query = reset ? {} : this.query || get(this, `${propsOrContext}.location.query`, {});
  this.query = {
    ...query,
    ...assignment,
  };
  if (this.lastQueryUpdate && Date.now() - this.lastQueryUpdate < 1000) {
    this.lastQueryUpdateCounter++;
    if (this.lastQueryUpdateCounter > 20) {
      throw new Error('updateQuery overload');
    }
  } else {
    this.lastQueryUpdate = Date.now();
    this.lastQueryUpdateCounter = 1;
  }
  replaceUrl({
    pathname: window.location.pathname,
    query: this.query,
  });
}

export const navigateToJob = (jobId) =>
  window.open(`${window.location.origin}${agentPrefix}/jobs?jobId=${jobId}`, window.hrefTarget);

export const onlyAlphanumeric = (value) => (value || '').replace(/[^A-Za-z0-9]/g, '');

export async function downloadFile(res, filename) {
  if (this.unmounted) {
    return;
  } else if (!this.anchorRef) {
    throw new Error('Anchor ref should be present!');
  } else if (res.status !== 200) {
    return this.setState({
      error: stringifyError({
        payload: await res.json(),
      }),
      downloading: false,
    });
  }
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  this.anchorRef.href = url;
  this.anchorRef.download = filename;
  this.anchorRef.click();
  window.URL.revokeObjectURL(url);
}

export const linkifyError = (error) => {
  const candidateMatch = /#candidate.*?#/gi.exec(error) || [];
  const contactMatch = /#contact.*?#/gi.exec(error) || [];
  const candidateId = (candidateMatch[0] || '').replace('#candidate', '').replace('#', '');
  const contactId = (contactMatch[0] || '').replace('#contact', '').replace('#', '');
  const makeLink = (entitiesType, userId) => `${window.location.origin}${agentPrefix}/${entitiesType}?userId=${userId}`;
  if (candidateId || contactId) {
    return (
      <Linkify>
        <span
          dangerouslySetInnerHTML={{
            __html: error
              .replace(/#candidate.*?#/gi, candidateId ? makeLink('candidates', candidateId) : '')
              .replace(/#contact.*?#/gi, contactId ? makeLink('contacts', contactId) : ''),
          }}
        />
      </Linkify>
    );
  }
  return error;
};

export const validateRequired = [(value) => (value ? undefined : translate('Field is required'))];

export const validateRequiredDraftJS = [
  (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.innerText ? undefined : translate('Field is required');
  },
];

const errorHasPath = (error) => get(error, 'path', []).length;

export const setNotificationComponent = (body) =>
  window.setNotificationComponent(
    <div>
      <div className="margin-top">{body}</div>
      <p className="small margin-top">
        <a href="mailto:hello@eva.ai">hello@eva.ai</a>
      </p>
    </div>,
    true,
  );

const mapErrorWithUnknownPath = (noPathError, index) => {
  if (noPathError.message) {
    return (
      <div key={index}>
        <strong>{noPathError.path.join('.')}</strong>: {noPathError.message}
      </div>
    );
  }
  return typeof noPathError === 'object' ? JSON.stringify(noPathError) : noPathError;
};

const mapErrorWithoutPath = (errorWithoutPath, index) => {
  if (errorWithoutPath.message) {
    return <div key={index}>{errorWithoutPath.message}</div>;
  }
  return typeof errorWithoutPath === 'object' ? JSON.stringify(errorWithoutPath) : `${errorWithoutPath}`;
};

export const wrappedProcessValidationErrors = (values, jsValues, errorData) => {
  const {
    status,
    payload: { handled, error, errors },
  } = errorData;
  const pathNotFound = 'path-not-found';
  if (error) {
    logError('Invalid error format!', { errorData });
    setNotificationComponent(
      <div>
        <img
          src={errorField}
          alt=""
          style={{
            maxWidth: '110px',
            marginTop: '-10px',
          }}
        />
        <h3 className="text-danger">
          {translate(status === 401 ? 'Operation is not allowed' : 'Oops, something went wrong')}
        </h3>
        <div className="text-black margin-bottom">
          {typeof error === 'object' && JSON.stringify(error, null, 1)}
          {typeof error !== 'object' && error}
        </div>
        {errorPopupMessage}
      </div>,
    );
    throw new SubmissionError({ _error: stringifyIfObject(error) });
  } else if (errors) {
    if (handled) {
      return;
    }

    logError(errors);

    const nonSystemErrors = errors.filter((item) => !item.code);
    const errorsWithPath = [];
    const errorsWithoutPath = [];
    nonSystemErrors.forEach((item) => (errorHasPath(item) ? errorsWithPath : errorsWithoutPath).push(item));

    const errorsWithUnknownPath = [];
    const fieldsErrors = errorsWithPath.reduce((prev, cur) => {
      const valueByPath = get(values, cur.path, pathNotFound);
      if (valueByPath === pathNotFound) {
        errorsWithUnknownPath.push(cur);
      } else {
        set(prev, typeof valueByPath === 'object' ? `${cur.path.join('.')}._error` : cur.path.join('.'), cur.message);
        const fieldName = cur.path.length ? cur.path[cur.path.length - 1] || '' : '';
        if (fieldName && `${fieldName}`.endsWith('Id')) {
          const curPathCopy = copy(cur.path);
          curPathCopy[curPathCopy.length - 1] = fieldName.substring(0, fieldName.length - 2);
          const jsValueByPath = get(jsValues, curPathCopy);
          if (jsValueByPath) {
            set(
              prev,
              typeof jsValueByPath === 'object' ? `${curPathCopy.join('.')}._error` : curPathCopy.join('.'),
              cur.message,
            );
          }
        }
      }
      return prev;
    }, {});
    if (errorsWithUnknownPath.length) {
      setNotificationComponent(
        <div>
          <img
            src={missingField}
            alt=""
            style={{
              maxWidth: '140px',
              marginTop: '-10px',
            }}
          />
          <h3 className="text-danger">{translate('Missing field error occurred')}</h3>
          <div className="text-black margin-bottom">{errorsWithUnknownPath.map(mapErrorWithUnknownPath)}</div>
          {errorPopupMessage}
        </div>,
      );
    } else if (errorsWithoutPath.length) {
      setNotificationComponent(
        <div>
          <img
            src={errorField}
            alt=""
            style={{
              maxWidth: '110px',
              marginTop: '-10px',
            }}
          />
          <h3 className="text-danger">{translate('Unhandled server error occurred')}</h3>
          <div className="text-black margin-bottom">{errorsWithoutPath.map(mapErrorWithoutPath)}</div>
          {errorPopupMessage}
        </div>,
      );
    }
    const unexpectedErrors = [...errorsWithUnknownPath, ...errorsWithoutPath];
    throw new SubmissionError({
      _error: unexpectedErrors.length
        ? unexpectedErrors.map((item) => item.message).join('\n')
        : translate('Form validation failed!'),
      ...fieldsErrors,
    });
  }
};

export const processValidationErrors =
  (values = {}, jsValues = {}) =>
  (...args) => {
    try {
      // @ts-expect-error
      return wrappedProcessValidationErrors(values, jsValues, ...args);
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      }
      logError(err, {
        values,
        args,
      });
      setNotificationComponent(
        <div>
          <img
            src={missingField}
            alt=""
            style={{
              maxWidth: '140px',
              marginTop: '-10px',
            }}
          />
          <h3 className="text-danger">{translate('Unhandled format error occurred')}</h3>
          {`${err}`}
        </div>,
      );
    }
  };

const stringifyIfObject = (obj) => (typeof obj === 'object' && obj !== null ? JSON.stringify(obj, null, 1) : obj);

export const stringifyError = (err) => {
  if (!err || typeof err !== 'object') {
    throw new Error('stringifyError: Unexpected error type!');
  } else if (!err.payload) {
    logError(err);
    throw new Error('stringifyError: Error should contain payload!');
  }
  const {
    payload: { error, errors },
  } = err;
  if (errors) {
    return errors
      .map(({ message, path }) => `${message}${message === 'Unknown field' && path ? `: ${path.join('.')}` : ''}`)
      .join('\n');
  } else if (error) {
    logError('Wrong error format!');
    return stringifyIfObject(error);
  }
  logError(err);
  return 'Unknown error format!';
};

export const onlyKeys = (items, key) => items.reduce((prev, cur) => [...prev, { [key]: cur[key] }], []);

export const loadingAlert = () => (
  <h3
    className="text-center"
    style={{
      padding: '50px',
      margin: 0,
    }}
  >
    {translate('Loading')}...
  </h3>
);

export const renderTextOrHtml = (textOrHtml) =>
  /(<([^>]+)>)/gi.test(textOrHtml || '') ? (
    <div dangerouslySetInnerHTML={{ __html: textOrHtml }} />
  ) : (
    <div style={{ whiteSpace: 'pre-wrap' }}>{textOrHtml}</div>
  );

export const mapLegendBlock = (block) => (
  <p key={block.label}>
    <span
      className="legend-item"
      style={{
        background: block.background,
      }}
    />{' '}
    {block.label}
  </p>
);

export const displayNameOptionRenderer = (option) => (option.name || {}).displayName;

export const displayNameFilterOptions = (options, searchString) =>
  options.filter((item) => (item.name.displayName || '').toLowerCase().includes(searchString.toLowerCase()));

export const isNumeric = (value) => !Number.isNaN(parseFloat(value)) && Number.isFinite(value);

export const configurableEntitiesPath = (entityType, entitiesType, entity, body: { [key: string]: any } = {}) => {
  let filter;
  let include;

  if (entityType === 'contact') {
    filter = {
      employer: {
        userId: [entity.userId],
      },
    };
  } else if (entityType === 'company') {
    filter = {
      company: {
        companyId: [entity.companyId],
      },
    };
  } else {
    throw new Error(`configurableEntitiesPath: can't process entityType ${entityType}`);
  }

  if (entitiesType === entitiesTypes.jobs) {
    include = ['salary'];
  } else if (entitiesType === entitiesTypes.contacts) {
    include = [];
  } else {
    throw new Error(`configurableEntitiesPath: can't process entitiesType ${entitiesType}`);
  }

  return [
    `/${entitiesType}/search`,
    {
      method: 'POST',
      body: JSON.stringify({
        filter,
        ...(body.offset === undefined ? { limit: 100 } : {}),
        include,
        orderBy: [],
        ...body,
      }),
    },
  ];
};

export const difference = (object, base, allowedDiff = []) => {
  const changes = (
    object,
    base, // eslint-disable-line no-shadow
  ) =>
    transform(object, (result, value, key) => {
      if (!allowedDiff.includes(key) && !isEqual(value, base[key])) {
        result[key] =
          value && typeof value === 'object' && typeof base[key] === 'object' ? changes(value, base[key]) : value;
      }
    });
  return changes(object, base);
};

export const copyToClipboard = (target) => {
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(target);
  selection.removeAllRanges();
  selection.addRange(range);
  const selectionText = selection.toString();
  if (!selectionText) {
    // eslint-disable-next-line no-alert
    return alert('Selection to copy was not found!');
  }
  try {
    document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert('Oops, unable to copy');
  }
  return selectionText;
};

export const replaceTags = (text, tags) => {
  if (!text) {
    throw new Error('Text should be defined');
  }
  return tags.reduce((prev, cur) => {
    if (!prev) {
      throw new Error(`Incorrect tags format, ${JSON.stringify(tags)}`);
    }
    return prev.replace(new RegExp(cur.code, 'g'), cur.name);
  }, text);
};

export const salaryRateLabel = (employmentType) => {
  if (!employmentType) {
    return translate('salary/rate');
  } else if (employmentType === employmentTypes.interimTemporary) {
    return translate('rate');
  }
  return translate('salary');
};

export const replaceCompanyName = (companyName) => (message) => ({
  ...message,
  content:
    typeof message.content === 'string' ? message.content.replace(/\${companyName}/g, companyName) : message.content,
});

export const minLength = (min) => (value) =>
  value && value.length < min ? translate('Must be {{min}} characters or more', { min }) : undefined;

export const getConversationId = (payload) => {
  const { message = {} } = payload;
  const mergedPayload = Object.assign({}, payload, message);
  return mergedPayload.conversationId || mergedPayload.webchatId || mergedPayload.omnichatId;
};

export const getMessageSender = (message) => {
  if (!message) {
    return {};
  }
  const messageTypeCode = get(message, 'messageType.code', 'text');
  return (
    (message[messageTypeCode] || message.text || message).sender || {
      userId: 8,
      name: {
        displayName: 'Eva System',
      },
    }
  );
};

export const mapTags = (tags) =>
  tags.map((tag) => ({
    ...tag,
    name: `{{ ${tag.name} }}`,
    code: `{{ ${tag.code} }}`,
  }));

/** @deprecated TODO remove in DEV-25601 */
export const getEmailTags = async () => {
  const tagsPromise = () => Promise.resolve(window.emailTags);
  if (window.emailTags) {
    return tagsPromise();
  }
  window.emailTags = mapTags(await requestBackend('/emails/tags'));
  return tagsPromise();
};

export const stripToId = (values, key, passedIdKey, passedValueIdKey) => {
  const defaultKey = `${key}Id`;
  const idKey = passedIdKey || defaultKey;
  const valueIdKey = passedValueIdKey || defaultKey;
  const passedValue = values[key];
  const idExtractable = passedValue && typeof passedValue === 'object';
  values[valueIdKey] = idExtractable ? passedValue[idKey] : passedValue;
  delete values[key];
};

export const getValidateRequired = (allowedValue = Symbol.for('allowedValue')) => {
  const required = (value) => (value || value === allowedValue ? undefined : translate('Field is required'));
  return [required];
};

export const validateRichHTML = (tags) => (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const matches = div.innerText.match(/{{(.*?)}}/g) || [];

  const invalidTag = matches.find((match) => {
    const strippedMatch = match.replace('{{', '').replace('}}', '').trim();
    const foundTag = tags.find((tag) => tag.code === `{{ ${strippedMatch} }}`);
    if (!foundTag) {
      return strippedMatch;
    }
    return null;
  });

  if (invalidTag) {
    return translate('Tag {{ invalidTag }} not found', {
      invalidTag,
    });
  }
  const openMatches = div.innerText.match(new RegExp('{{', 'g')) || [];
  const closeMatches = div.innerText.match(new RegExp('}}', 'g')) || [];
  if (openMatches.length !== closeMatches.length) {
    return translate('Open tags {{ count is not equal with close tags }} count');
  }
};

export const getFileExtension = (filename) => {
  const extensionExec = /\.\w{3,4}($|\?)/.exec(filename);
  if (extensionExec) {
    const extension = extensionExec[0].toLowerCase().replace('?', '');
    if (extension === '.pdf') {
      return fileExtensions.pdf;
    } else if (extension === '.txt') {
      return fileExtensions.txt;
    } else if (imagesExtensions.test(extension)) {
      return fileExtensions.image;
    } else if (cvExtensions.test(extension)) {
      return fileExtensions.document;
    }
  }
};

export const populateLocation = (change, location, prefix = 'location.') =>
  locationKeys.forEach((fieldKey) => change(`${prefix}${fieldKey}`, get(location, fieldKey, '')));

export const populateLocationFromCompanyId = async (companyId, locationToFill) => {
  const change = (field, value) => (locationToFill[field] = value);

  return requestBackend(`/companies/${companyId}`).then(({ location }: any) => populateLocation(change, location, ''));
};

export const showStickyDate = (show = true) => {
  const stickyDateElement: any = document.querySelector('#messages-sticky-date');
  if (!stickyDateElement) {
    return logError('stickyDateElement not found!');
  } else if (show === false) {
    stickyDateElement.style.display = 'none';
  }
  const messageWrapperElements = Array.prototype.slice.call(document.querySelectorAll('.message-wrapper'));
  const bodyRect = document.body.getBoundingClientRect();
  const paneRect = stickyDateElement.getBoundingClientRect();
  const overlappedMessage = messageWrapperElements.reverse().find((element) => {
    const elemRect = element.getBoundingClientRect();
    return elemRect.top - bodyRect.top < paneRect.bottom;
  });
  if (overlappedMessage) {
    stickyDateElement.innerText = overlappedMessage.getAttribute('data-date');
    stickyDateElement.style.display = 'block';
  } else {
    stickyDateElement.style.display = 'none';
  }
};

export const invalidBounce = (emails, emailType) => get(emails, `${emailType}.bounceTypeId`) === 1;

export const renderEmail = (email) => (
  <span
    className="text-overflow"
    style={{
      maxWidth: '100%',
      display: 'inline-block',
    }}
  >
    {email ? (
      <a href={`mailto:${email.emailAddress}`} title={email.emailAddress}>
        {email.emailAddress}
      </a>
    ) : (
      ''
    )}
  </span>
);

export const toQueryString = (params: any) =>
  Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

export const isExternalCandidate = (compositeId) => compositeId && `${parseInt(compositeId, 0)}` !== `${compositeId}`;

export const getQueryVariables = (): { [key: string]: string } => {
  const queryParams = window.location.search.substring(1).split('&');
  return queryParams
    .filter((queryParam) => queryParam)
    .reduce((prev, cur) => {
      const [key, value] = cur.split('=');
      return {
        ...prev,
        [decodeURIComponent(key)]: decodeURIComponent(value),
      };
    }, {});
};

export const setQueryVariables = (update) => {
  const queryVariables = getQueryVariables();
  Object.keys(update).forEach((updateKey) => {
    const updateValue = update[updateKey];
    if (updateValue === undefined) {
      delete queryVariables[updateKey];
    } else {
      queryVariables[updateKey] = updateValue;
    }
  });
  const queryVariablesString = toQueryString(queryVariables);
  const queryString = queryVariablesString ? `?${toQueryString(queryVariables)}` : '';
  if (queryString !== window.location.search) {
    window.history.pushState(null, null, `${window.location.pathname}${queryString}`);
  }
};

export const clearQueryVariables = () => {
  const variablesToClear = clearableMessengerQueryParams.reduce((acc, key) => {
    acc[key] = undefined;
    return acc;
  }, {});

  setQueryVariables(variablesToClear);
};

export const prepareMessage = (candidateId, message) => {
  const storageUser = localStorage.user ? getParsedUser() : {};
  const sender = getMessageSender(message);
  const senderUserId = `${sender.userId}`;
  const agentSide = senderUserId !== `${storageUser.userId}`;
  if (!message.sentOn) {
    logError('Missing sentOn', { message });
  }
  return {
    ...message,
    conversationId: message.conversationId || message.omnichatId,
    sentOn: message.sentOn ? momentify(message.sentOn) : null,
    readOn:
      message.readOn || message.senderId == storageUser.userId // eslint-disable-line eqeqeq
        ? momentify(message.readOn)
        : null,
    agentSide,
  };
};

export const getYearsOptions = (before = 90, after = 0) => {
  const yearsOptions = [];
  const currentYear = moment().year();
  for (let shift = 0; shift < before; shift++) {
    const yearToAdd = `${currentYear - shift}`;
    yearsOptions.unshift({
      value: yearToAdd,
      label: yearToAdd,
    });
  }
  for (let shift = 1; shift <= after; shift++) {
    const yearToAdd = `${currentYear + shift}`;
    yearsOptions.push({
      value: yearToAdd,
      label: yearToAdd,
    });
  }
  return yearsOptions;
};

export const getOpenedInModal = (props) => props.parent.constructor.name === 'ModalEntity';

export const connectInfluencingEvents = (self) => {
  const { socket } = self.context;
  const { influencingEvents } = self.props;

  if (getOpenedInModal(self.props)) {
    return;
  }

  if (influencingEvents) {
    if (!socket) {
      logError('Socket should be present to connect influencing events', { self });
      return;
    }
    self.eventHandlers = {};
    Object.entries(influencingEvents).forEach(([eventKey, eventHandler]: any) => {
      self.eventHandlers[eventKey] = (...args) => eventHandler(self, ...args);
      socket.on(eventKey, self.eventHandlers[eventKey]);
    });
  }
};

export const disconnectInfluencingEvents = (self) => {
  const { socket } = self.context;
  if (socket && self.eventHandlers) {
    Object.entries(self.eventHandlers).forEach(([eventKey, eventHandler]) =>
      socket.removeListener(eventKey, eventHandler),
    );
  }
};

export const labeledItems = (items, itemslLabel) =>
  items.length ? (
    <div>
      <label className="control-label margin-right">{translate(itemslLabel)}:</label>
      <PopperPopover
        placement="left"
        delayShow={500}
        delayHide={2000}
        overlay={<small>{items.map(({ name, label }) => name || label).join(', ')}</small>}
      >
        <span className="text-overflow prev-value">{items.map(({ name, label }) => name || label).join(', ')}</span>
      </PopperPopover>
    </div>
  ) : null;

export const makeQueryString = (values) =>
  Object.entries(values)
    .filter((entry) => entry[1])
    .map((entry) => `${entry[0]}=${entry[1]}`)
    .join('&');

export const workflowStateOptionRenderer = (initialOption, showStage = true) => {
  const { normalizedPipelineStages } = window.settings;
  const option = initialOption || {};
  const workflowStage = normalizedPipelineStages[option.workflowStageId];
  return (
    <span>
      <i className="fa fa-circle margin-right" style={{ color: option.color || greyColor }} />
      {showStage && workflowStage ? `${workflowStage.name}: ${option.name}` : option.name}{' '}
      {option.isTerminal ? `(${translate('Terminal')})` : ''} {option.disabledFrom ? `(${translate('Disabled')})` : ''}
    </span>
  );
};

export const renderWorkflowStateBadge = (workflowState) => (
  <span className="badge" style={{ background: workflowState ? workflowState.color : '' }}>
    {workflowState.name}
  </span>
);

export const groupAndSortPipelines = (settings, pipelines, hideInactivePipelines) => {
  const { normalizedPipelineStates, normalizedPipelineWorkflows, workflowStages } = settings;

  return pipelines
    .filter((pipeline) => {
      if (!hideInactivePipelines) {
        return true;
      }
      const workflowStateCode = get(pipeline, 'job.workflowState.code');
      return !workflowStateCode || jobsStatuses.active.ids.includes(workflowStateCode);
    })
    .map((pipeline) => {
      const { workflowState } = pipeline;

      let grouper;
      if (!workflowState.workflowStateId && workflowState.code) {
        grouper = {
          group: 0,
          groupLabel: translate('External'),
          color: '#B2C3CB',
          total: 0,
        };
      } else {
        const normalizedPipelineState = normalizedPipelineStates[workflowState.workflowStateId];
        if (!normalizedPipelineState) {
          logError(`normalizedPipelineState not found by ID ${workflowState.workflowStateId}`, {
            normalizedPipelineStates,
          });
          return {
            jobId: pipeline.job.jobId,
            group: 0,
            groupLabel: translate('Stage not identified'),
            color: greyColor,
            ...pipeline,
          };
        }
        const workflowStage = workflowStages.find(
          ({ workflowStageId }) => workflowStageId === normalizedPipelineState.workflowStageId,
        );
        if (!workflowStage) {
          grouper = {
            group: 1000998,
            groupLabel: translate('Stage not set'),
            color: '#B2C3CB',
          };
        } else if (workflowState.isTerminal) {
          grouper = {
            group: 1000999,
            groupLabel: translate('Rejected'),
            color: '#F79BA3',
          };
        } else {
          const pipelineWorkflow = normalizedPipelineWorkflows[workflowStage.pipelineWorkflowId] || {};
          grouper = {
            group: pipelineWorkflow.order * 1000 + workflowStage.sortOrder,
            groupLabel: `${pipelineWorkflow.name}: ${workflowStage.name}` || '??',
          };
        }
        grouper.color = grouper.color || workflowStage.color || '#B2C3CB';
      }
      return {
        jobId: pipeline.job.jobId,
        ...grouper,
        ...pipeline,
      };
    })
    .sort((prev, next) => {
      if (prev.group !== next.group) {
        if (prev.group < 10 && next.group < 10) {
          return prev.group > next.group ? -1 : 1;
        }
        return prev.group > next.group ? 1 : -1;
      }
      return prev.updatedAt > next.updatedAt ? -1 : 1;
    });
};

export const isMobileMode = () => window.innerWidth < 769;

export const notForExternal = ({ compositeId }) => !isExternalCandidate(compositeId);

export const highlighted = (highlight, value, defaultValue) => get(highlight, [value, 0], defaultValue);

export const prepareHighlights = (highlight = {}, path = '') =>
  (highlight[path] || []).reduce(
    (prev, cur) => ({
      ...prev,
      [cur.replace(/<mark>|<\/mark>/gi, '')]: cur,
    }),
    {},
  );

export const highlightedSpan = (highlights, text) => {
  let __html = text;
  if (highlights[text]) {
    __html = highlights[text];
  } else if (text) {
    // Elasticsearch highlight phrase can be bugged
    Object.keys(highlights)
      .filter((highlight) => text.includes(highlight))
      .forEach((occurrence) => (__html = text.replace(occurrence, highlights[occurrence])));
  }
  return <span dangerouslySetInnerHTML={{ __html }} />;
};

export const textToBoolean = (value, defaultValue) => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (value === 'null') {
    return null;
  }
  return defaultValue;
};

export const onTokenFailed = (paramRedirect) => {
  const queryVariables: { [key: string]: any } = getQueryVariables();
  const redirect = paramRedirect || queryVariables.url || queryVariables.redirect || '';

  replaceUrl(`/auth/sign-in?redirect=${encodeURIComponent(redirect)}`);
};

export const nullifySalary = ({ salary }) => ({
  salary: get(salary, 'period') ? salary : null,
});

export const objectifySalary = ({ salary }) => ({
  salary: salary
    ? {
        value: salary.value,
        period: salary.period.code,
      }
    : {},
});

export const redirectToSSO = (redirect) => {
  const href = `${baseEndpoint}/api/authentication/auth/saml?redirect=${encodeURIComponent(redirect)}`;

  if (iframeMode) {
    window.open(href, '_blank').focus();
  } else {
    replaceUrl(href);
  }
};

let monthsOptions = [];

export const getMonthsOptions = () => {
  if (monthsOptions && monthsOptions.length) {
    return monthsOptions;
  }
  monthsOptions = [];
  const monthMoment = moment().month(0);
  for (let shift = 1; shift <= 12; shift++) {
    monthsOptions.push({
      value: shift,
      label: monthMoment.format('MMMM'),
    });
    monthMoment.add(1, 'month');
  }
  return getMonthsOptions();
};

export const isAgentIntersect = (accountTypes = []) => !!intersection(agentContactAccountTypes, accountTypes).length;

export const isCandidateIntersect = (accountTypes = []) =>
  !!intersection([userAccountTypes.candidate], accountTypes).length;

export const getAccountTypes = ({ accountTypes, userType }) =>
  accountTypes || [invertedUserTypes[userType]].filter(Boolean);

export const getAgentOrigin = () => {
  if (localHostnames.includes(window.location.hostname)) {
    return `http://${window.location.hostname}:3001`;
  }
  return window.location.origin;
};

export const getCandidateTraces = () =>
  ['firstName', 'emailcandidate', 'passwordcandidate'].some((localStorageKey) => localStorage[localStorageKey]);

/** @deprecated TODO remove in DEV-25601 skillsSet logic */
export const getMessageOptions = (message) =>
  ['skillsSet', 'skillsSetQ2'].includes(message.options?.candidateCustomField)
    ? {
        hideTextarea: true,
        customFrontend: 'eucf',
        customMessage: 'MessageSkillsTaxonomy',
      }
    : message.options || {};

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const loadCustomComponents = async (settings) => {
  if (settings.customFrontend?.module === 'eucf') {
    const { default: module } = await import('@eva/eucf/app/containers/customComponents');
    return module;
  }
  return Promise.resolve();
};

export const normalizeSalaryValue = (desiredSalary) => {
  if (!desiredSalary?.salary) {
    return desiredSalary;
  }

  const { salary } = desiredSalary;

  return {
    salary: {
      ...salary,
      value: typeof salary.value === 'string' ? Number(salary.value) : salary.value,
    },
  };
};

export const getCandidateChatTopics = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value.split(';').map((item, index) => ({ topic: item, selectJob: index === 0 }));
  }
};

export const valuesWithDelimiter = (values) =>
  values
    .filter((value) => value)
    .map((value, index) => (
      <span key={index}>
        {!!index && <span className="margin-left margin-right fa fa-circle text-muted small-dots" />}
        {value}
      </span>
    ));

export const getUserDropdownLinks = () => [
  {
    permission: 'frontend-userProfileSettings-view',
    pathname: '/auth/profile',
    icon: 'lnr lnr-user margin-right',
    label: translate('Profile'),
    isExternalLink: true,
  },
];

export { getTokenPayload };
